<template>
    <div class="user-profile">
        <div class="box">
            <h2>ข้อมูลรถยนต์</h2>

            <div class="loading" v-if="!isContentReady">
                <loader-content :isDisplay="true"></loader-content>
            </div>
            
            <template v-if="isContentReady">
                <div class="car" v-for="(item, index) in cars" :key="index">
                    <div class="number">
                        {{ index + 1 }}
                    </div>
                    <div class="data">
                        <h4>
                            {{ item.licenseNumber }}
                            <span>{{ item.lienseProvince }}</span>
                        </h4>
                        <span>{{ item.carBrand }} {{ item.carModel }}</span>
                        <span v-if="item.carColor">{{ item.carColor }}</span>
                        <span v-if="item.carDistance">เลชไมล์ {{ item.carDistance }}</span>
                    </div>
                    <button @click="deleteCar(item)">ลบข้อมูล</button>
                </div>

                <div class="mt-4">
                    <button class="btn btn-main btn-add-car square" @click="openCarModal()">+ เพิ่มรถใหม่</button>
                </div>
            </template>
        </div>

        <modal-car @close="closeCarModal($event)" :isDisplay="isOpenCarModal"></modal-car>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import UserService from '@/services/userService';
import ModalCar from '@/components/user/ModalCar.vue';

export default {
    components: { ModalCar },
    data() {
        return {
            isShowForm: true,
            isContentReady: false,
            isOpenCarModal: false,
            cars: []
        };
    },
    mounted() {
        this.loadCar();
    },
    methods: {
        async loadCar() {
            this.isContentReady = false;

            const result = await UserService.getCar();

            this.cars = result.data;
            this.isContentReady = true;
        },
        openCarModal() {
            this.isOpenCarModal = true;
        },
        closeCarModal(data) {
            this.isOpenCarModal = false;

            if (data.isReload) {
                this.loadCar();
            }
        },
        deleteCar(item) {
            this.$swal(
                Helper.confirmAlert('ยืนยันการลบข้อมูลรถ', 'โปรดยืนยันว่าต้องการลบรถคันนี้ออกจากโปรไฟล์ของท่าน')
            ).then(async (confirm) => {
                if (confirm.isConfirmed) {
                    let loader = this.$modalLoader.render();
                    await UserService.deleteCar(item.id);
                    loader.hide();

                    this.loadCar();
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/user/style/ProfileSetting.scss';

.loading {
    height: 200px;
}

.car {
    display: flex;
    align-items: flex-start;
    margin-top: 25px;

    .number {
        width: 30px;
        height: 30px;
        padding: 2px 4px;
        border: 1px solid #666;
        text-align: center;
    }

    .data {
        padding: 0 20px;
        width: 250px;

        h4 {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: bold;

            & > span {
                margin-left: 10px;
            }
        }

        span {
            display: block;
            font-size: 13px;
            color: #777;
        }
    }

    button {
        background: none;
        border: none;
        text-decoration: underline;
        font-size: 12px;
        color: #333;
    }
}

.btn-add-car {
    margin-left: 50px;
    width: 120px;
    height: 40px;
}
</style>